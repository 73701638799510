import { SVGProps } from "react"

const BookingSwLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="33"
    viewBox="0 0 36 33"
    fill="none"
    {...props}
  >
    <path
      d="M3.52344 22.7215V11.8992C3.52344 5.61847 8.63148 0.500488 14.9221 0.500488H34.7978C35.1953 0.500488 35.5232 0.828436 35.5232 1.22595V6.61225C35.5232 7.00977 35.1953 7.33771 34.7978 7.33771H17.0687C17.0091 7.33771 16.9494 7.34765 16.8998 7.37746C15.4488 7.51659 14.0973 8.08305 12.9743 9.00727C11.3644 10.329 10.4302 12.2072 10.3209 14.2843C10.2315 16.1327 10.8973 18.001 12.1992 19.5314L14.9023 22.7115H3.52344V22.7215Z"
      fill="#404041"
    />
    <path
      d="M0.748898 32.5006C0.351385 32.5006 0.0234375 32.1726 0.0234375 31.7751V26.3888C0.0234375 25.9913 0.351385 25.6634 0.748898 25.6634H17.0668C17.5339 25.6634 17.9712 25.4646 18.2693 25.1069L23.1587 19.3529C25.3848 16.7293 25.4841 12.9926 23.4668 10.2896H32.0232V21.1019C32.0232 27.3826 26.9152 32.5006 20.6246 32.5006H0.748898Z"
      fill="#2A7DE1"
    />
    <path
      d="M17.7673 14.9692C18.9419 14.9692 19.894 14.0171 19.894 12.8425C19.894 11.668 18.9419 10.7158 17.7673 10.7158C16.5928 10.7158 15.6406 11.668 15.6406 12.8425C15.6406 14.0171 16.5928 14.9692 17.7673 14.9692Z"
      fill="#404041"
    />
  </svg>
)

export default BookingSwLogo

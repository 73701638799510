import { SVGProps } from "react"

const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4777_305823)">
      <path
        d="M6.9974 12.8327C7.76358 12.8336 8.5224 12.6832 9.23026 12.39C9.93811 12.0967 10.5811 11.6666 11.1221 11.1241C11.6646 10.583 12.0948 9.94007 12.388 9.23221C12.6812 8.52435 12.8317 7.76554 12.8307 6.99935C12.8317 6.23317 12.6813 5.47434 12.3881 4.76648C12.0948 4.05862 11.6646 3.41568 11.1221 2.8746C10.5811 2.33212 9.93814 1.90192 9.23028 1.6087C8.52241 1.31549 7.76359 1.16504 6.9974 1.16602C6.23121 1.16504 5.47239 1.31549 4.76453 1.6087C4.05666 1.90192 3.41372 2.33212 2.87265 2.8746C2.33017 3.41568 1.89996 4.05862 1.60675 4.76648C1.31353 5.47434 1.16309 6.23317 1.16407 6.99935C1.16309 7.76554 1.31353 8.52437 1.60675 9.23223C1.89996 9.94009 2.33017 10.583 2.87265 11.1241C3.41372 11.6666 4.05666 12.0968 4.76453 12.39C5.47239 12.6832 6.23121 12.8337 6.9974 12.8327V12.8327Z"
        stroke="#898A93"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.0026 3.20898C7.19599 3.20898 7.38146 3.28581 7.5182 3.42255C7.65495 3.5593 7.73177 3.74476 7.73177 3.93815C7.73177 4.13154 7.65495 4.317 7.5182 4.45375C7.38146 4.59049 7.19599 4.66732 7.0026 4.66732C6.80922 4.66732 6.62375 4.59049 6.48701 4.45375C6.35026 4.317 6.27344 4.13154 6.27344 3.93815C6.27344 3.74476 6.35026 3.5593 6.48701 3.42255C6.62375 3.28581 6.80922 3.20898 7.0026 3.20898Z"
        fill="#898A93"
      />
      <path
        d="M7.14583 9.91732V5.83398H6.5625"
        stroke="#898A93"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.125 9.91602H8.16667"
        stroke="#898A93"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4777_305823">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default InfoIcon

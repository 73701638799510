import * as Sentry from "@sentry/nextjs"
import { Alert, Button, Spinner, TextInput } from "flowbite-react"
import { Form, Formik } from "formik"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { useState } from "react"
import * as Yup from "yup"

import InputRadio from "@components/elements/CustomRadio"
import styles from "@components/elements/CustomRadio/inputbox.module.css"
import FormGroup from "@components/elements/FormGroup"
import PasswordInput from "@components/elements/PasswordInput"
import AuthLayout from "@components/layouts/auth"
import useAuth from "@hooks/useAuth"
import BookingSwLogo from "@icons/BookingSwLogo"
import Google from "@icons/Google"
import { GET_SUB_DOMAIN } from "@mono/essentials/constants/regex"
import routes from "@mono/essentials/constants/routes"
import * as AuthServices from "@mono/essentials/services/AuthServices"
import { getDomainName, getSubDomain } from "@mono/essentials/utils/Common"

import InfoSection from "./InfoSection"

import type { FormikHelpers } from "formik"

export type LoginFormAttributes = {
  email: string
  password: string
}

const LoginPage = () => {
  const router = useRouter()
  const [formError, setFormError] = useState<string>()
  const {
    setToken,
    setIsAuthenticated,
    getAuthenticatedUsers,
    setSelectedTenant,
    setSelectedDomain,
    setSelectedDomainTimeZone,
    setSelectedDomainCurrency,
  } = useAuth() ?? {}

  const initialValues: LoginFormAttributes = {
    email: "",
    password: "",
  }

  const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email(`Please enter your account's email`)
      .required("*Please enter your email"),
    password: Yup.string().required("*Password Required").min(8, "Too Short!"),
  })

  const onSubmit = async (
    values: LoginFormAttributes,
    { setSubmitting, setFieldError }: FormikHelpers<LoginFormAttributes>
  ) => {
    try {
      setFormError("")
      await AuthServices.csrfCookie()
      setSubmitting(true)
      const { data } = await AuthServices.login(values)
      if (data?.token.access_token) {
        setToken?.(() => data.token.access_token)
        setSubmitting(true)
        if (data?.tenants?.length) {
          // TODO: Need to create a new screen for selecting the tenant.
          const selectedTenant = data.tenants[0]
          let latest_domain = selectedTenant.domains[selectedTenant.domains.length - 1 ?? 0]
          const subDomainInUrl = getSubDomain(window.location.host)
          if (subDomainInUrl) {
            selectedTenant.domains.forEach((domain) => {
              if (domain.domain === subDomainInUrl) {
                latest_domain = domain
              }
            })
          }
          setSelectedTenant?.(selectedTenant.id)
          setSelectedDomain?.(latest_domain.id)
          setSelectedDomainTimeZone?.(latest_domain.timezone_iso3)
          setSelectedDomainCurrency?.(latest_domain.currency_symbol)
          window.location.assign(
            `${window.location.protocol}//${latest_domain.domain}.${getDomainName(true)}/dashboard`
          )
        } else {
          setFormError("Something went wrong! please try again.")
        }
      }
    } catch (e: any) {
      Sentry.captureException(e)
      const errors = e?.response?.data?.errors || {}
      for (const key of Object.keys(errors)) {
        setFieldError(key, errors[key][0])
      }
      if (e?.response?.data?.message) {
        setFormError(e?.response?.data?.message)
      }
      setSubmitting(false)
    }
  }

  return (
    <AuthLayout leftArea={<InfoSection />}>
      <div className={"absolute hidden right-10 top-10 md:block"}>
        {process.env.NEXT_PUBLIC_HIDE_SIGNUP !== "true" && (
          <Button onClick={() => router.push(routes.register)} color={"ghost"} size={"md"}>
            Create Account
          </Button>
        )}
      </div>
      <div className={"w-full m-auto"}>
        <Link href={"/"}>
          <BookingSwLogo />
        </Link>
        <h5 className={"pt-2 text24Regular text-neutral-1000"}>Welcome back to</h5>
        <h4 className={"text32Semibold text-primary-1000"}>SmartRez</h4>
        <h6 className={"pt-2 text16Regular text-neutral-600"}>Sign in to your account.</h6>
        <div className={"pt-10 pb-5"}>
          {formError && (
            <Alert color={"failure"} className={"mb-3"}>
              <span>{formError}</span>
            </Alert>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={loginValidationSchema}
            onSubmit={onSubmit}
          >
            {({ getFieldProps, isSubmitting, errors, touched, values, setFieldValue }) => (
              <Form autoComplete={"off"} className={"pb-6"}>
                <FormGroup label={"Email"} name={"email"} className={"mt-6"}>
                  <TextInput
                    {...getFieldProps("email")}
                    sizing={"lg"}
                    type={"email"}
                    className={"solidInput"}
                    placeholder={"e.g. john@example.com"}
                    color={errors.email && touched.email ? "failure" : undefined}
                    id={"operator-login-email-field"}
                  />
                </FormGroup>
                <FormGroup label={"Password"} name={"password"} className={"mt-6"}>
                  <PasswordInput
                    {...getFieldProps("password")}
                    sizing={"lg"}
                    className={"solidInput"}
                    placeholder={"Password"}
                    color={errors.password && touched.password ? "failure" : undefined}
                    id={"operator-login-password-field"}
                  />
                </FormGroup>
                <Link href={routes.forgotPassword} className={""}>
                  <p className={"pt-2 text-right text12Medium text-primary-600 hover:underline"}>
                    Forgot Password?
                  </p>
                </Link>
                <Button
                  fullSized
                  type={"submit"}
                  size={"lg"}
                  color={"primary"}
                  className={"mt-8"}
                  disabled={isSubmitting}
                  id={"operator-login-submit-button"}
                >
                  {isSubmitting ? <Spinner size={"sm"} light /> : "Sign in"}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </AuthLayout>
  )
}

export default LoginPage

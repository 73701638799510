export const PASSWORD_ATLEAST_ONE_NUMBER = /[0-9]/g
export const PASSWORD_ONE_SPECIAL_CHARACTER = /[#?!@$%^&*-]/g
export const PASSWORD_ATLEAST_SIX_CHARACTERS = /.{8,}/g
export const PASSWORD_ONE_UPPERCASE_LOWERCASE = /(?=.*[a-z])(?=.*[A-Z])/g
export const GET_SUB_DOMAIN = /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i
export const COMMUNICATION_CATEGORY_VARIABLE = /\{\%(.+?)\%\}/g
export const CATEGORY_VARIABLE_REPLACER = /\{\%|\%\}/g
export const WHITESPACE = / /g
export const WAIVER_INITIAL_REGEX = /\[booking_initial(.*?)\]/g
export const WAIVER_SIGNED_DATE = /\[Sign Date(.*?)\]/g
export const WAIVER_FIRST_NAME = /\[First Name(.*?)\]/g
export const WAIVER_LAST_NAME = /\[Last Name(.*?)\]/g
export const WAIVER_FULL_NAME_INSTRUCTOR = /\[Full Name Instructor(.*?)\]/g
export const WAIVER_FULL_NAME_RENTER = /\[Full Name Renter(.*?)\]/g
export const WAIVER_SINGATURE_REGEX = /\[booking_signature(.*?)\]/g
export const EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

import { Tooltip, TooltipProps } from "flowbite-react"
import { ErrorMessage, useFormikContext } from "formik"
import { get } from "lodash"
import React from "react"

import InfoIcon from "@icons/Info"

interface FormGroupProps {
  children: React.ReactNode
  label?: string
  name: string
  renderError?: boolean
  className?: string
  errorClassName?: string
  labelClassName?: string
  childrenClassName?: string
  requiredField?: boolean
  direction?: "vertical" | "horizontal"
  tooltipProps?: TooltipProps
  showOnlyInfoIcon?: boolean
}

const FormGroup = ({
  children,
  label,
  name,
  renderError = true,
  className,
  requiredField = false,
  labelClassName,
  errorClassName,
  childrenClassName = "",
  direction = "vertical",
  tooltipProps,
  showOnlyInfoIcon = false,
}: FormGroupProps) => {
  const { errors, values, touched, submitCount } = useFormikContext()
  const error = get(errors, name)
  const value = get(values, name)
  const fieldTouched = get(touched, name)

  return (
    <div
      aria-invalid={!!error && (!!submitCount || (fieldTouched && !!value))}
      className={`
        ${direction === "horizontal" ? "flex flex-row items-center justify-between gap-2" : ""}
        ${className ?? ""}
      `}
    >
      {label && (
        <label
          className={`
          ${direction === "vertical" ? "text14Medium mb-1 text-neutral-900" : "text14Medium text-neutral-900"}
          ${direction === "horizontal" && error && fieldTouched ? "mb-[18px]" : ""}
          ${labelClassName ?? ""}
          flex items-center justify-start
        `}
        >
          {label}
          {tooltipProps ? (
            <Tooltip {...tooltipProps}>
              <InfoIcon className={"ml-1"} />
            </Tooltip>
          ) : showOnlyInfoIcon ? (
            <InfoIcon className={"ml-1"} />
          ) : null}
          {requiredField ? <span className={"ml-[3px] text-red-600"}>*</span> : ""}
        </label>
      )}
      <div className={`flex flex-1 flex-col items-start gap-1 ${childrenClassName}`}>
        {children}
        {renderError && (
          <ErrorMessage
            component={"p"}
            name={name}
            className={`text12Regular text-red-600 ${errorClassName}`}
          />
        )}
      </div>
    </div>
  )
}

export default FormGroup

import { Checkbox, TextInput } from "flowbite-react"
import { useFormikContext } from "formik"
import { get } from "lodash"
import React, { useState } from "react"

import { FilledEyesIcon, PreviewCloseIcon } from "@assets/icons"
import {
  PASSWORD_ATLEAST_ONE_NUMBER,
  PASSWORD_ATLEAST_SIX_CHARACTERS,
  PASSWORD_ONE_SPECIAL_CHARACTER,
  PASSWORD_ONE_UPPERCASE_LOWERCASE,
} from "@mono/essentials/constants/regex"

import type { TextInputProps } from "flowbite-react"

interface PasswordInputProps extends TextInputProps {
  name: string
  showPasswordStrength?: boolean
  showPasswordStrengthList?: boolean
  hint?: string
}

const PasswordInput = ({
  name,
  showPasswordStrength = false,
  showPasswordStrengthList = false,
  hint,
  ...props
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const { values } = useFormikContext()
  const value = get(values, name)

  const toggleShowPassword = () => setShowPassword((prev) => !prev)

  const passwordTracker = {
    number: value.match(PASSWORD_ATLEAST_ONE_NUMBER),
    specialChar: value.match(PASSWORD_ONE_SPECIAL_CHARACTER),
    sixCharsOrGreater: value.match(PASSWORD_ATLEAST_SIX_CHARACTERS),
    upperLowerChar: value.match(PASSWORD_ONE_UPPERCASE_LOWERCASE),
  }

  const STRENGTH_CHECKLIST = [
    {
      CheckerItem: !passwordTracker.sixCharsOrGreater,
      label: "Minimum of 8 characters",
    },
    {
      CheckerItem: !passwordTracker.upperLowerChar,
      label: "At least 1 upper and lower case",
    },
    {
      CheckerItem: !passwordTracker.number,
      label: "At least 1 number (0-9)",
    },
    {
      CheckerItem: !passwordTracker.specialChar,
      label: "At least 1 special characters",
    },
  ]

  const passwordStrength = Object.values(passwordTracker).filter((value) => value).length

  const StrengthChecker = () => {
    if (value.length) {
      if (passwordStrength < 4) {
        return <span className={"text-xs font-normal text-red-500"}>Weak</span>
      } else if (passwordStrength === 4 && value.length < 8) {
        return <span className={"text-blue800 text-xs font-normal"}>Medium</span>
      } else if (passwordStrength === 4 && value.length >= 8) {
        return <span className={"text-orange500 text-xs font-normal"}>Good</span>
      }
      return <span className={"text-xs font-normal text-red-500"}>Weak</span>
    }
  }

  return (
    <>
      <TextInput
        {...props}
        name={name}
        type={showPassword ? "text" : "password"}
        rightIcon={() =>
          showPassword ? (
            <FilledEyesIcon
              onClick={toggleShowPassword}
              className={"cursor-pointer text-neutral-400"}
              height={18}
              width={18}
            />
          ) : (
            <PreviewCloseIcon
              onClick={toggleShowPassword}
              className={"cursor-pointer text-neutral-400"}
              height={18}
              width={18}
            />
          )
        }
      />
      {showPasswordStrength || showPasswordStrengthList ? (
        <div>
          {showPasswordStrength ? (
            <p className={"text12Regular pb-4 pt-2 text-neutral-500"}>
              Password Strength: <span className={"text-orange-700"}>{StrengthChecker()}</span>
            </p>
          ) : null}
          {hint ? <p className={"text12Regular pb-4 pt-2 text-neutral-500"}>{hint}</p> : null}
          {showPasswordStrengthList ? (
            <div className={"flex flex-col items-start justify-start gap-2"}>
              {STRENGTH_CHECKLIST.map((item, key) => (
                <div className={"flex"} key={key}>
                  <Checkbox
                    checked
                    className={`${item.CheckerItem ? "text-neutral-200" : "text-green-600"} h-4 w-4 rounded-xl`}
                    readOnly
                  />
                  <p className={"text14Regular ml-2 text-neutral-1000"}>{item.label}</p>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  )
}

export default PasswordInput
